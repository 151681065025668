import React from 'react'
  import { MDXTag } from '@mdx-js/tag'


  import DefaultLayout from "/opt/build/repo/src/templates/page.jsx";
import { Button } from '../../components/Button';
import { CryptoAddresses } from '../../components/CryptoAddresses';
import { TippinWidget } from '../../components/TippinWidget';
const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = DefaultLayout;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" Layout={this.layout} layoutProps={Object.assign({}, layoutProps, props)} components={components}>


      <MDXTag name="h2" components={components}>{`Sme nezisková organizácia, nezávislá na štátnych peniazoch. Pri našej snahe sú dôležité (aj tvoje) dobrovoľné príspevky.`}</MDXTag>
      <MDXTag name="p" components={components}>{`V Paralelnej Polis Košice chceme vytvoriť prostredie zamerané na vzdelávanie, diskusiu, objavovanie a tvorenie lepších systémov pre naše okolie - teba, nás, firmy, jednotlivcov - všetkých, ktorí majú záujem fungovať slobodnejšie a nezávislejšie. Môžeš si prečítať viac `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "/o-paralelnej-polis"
        }}>{`o nás, našich hodnotách a vízii`}</MDXTag>{`.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Práve preto potrebujeme každú pomoc – od fanúšikov a ľudí, ktorí sa vďaka našim akciám posúvajú dopredu.`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`Ak máš návrh na inú formu podpory, `}<MDXTag name="a" components={components} parentName="strong" props={{
            "href": "mailto:info@ppke.sk?subject=Podpora"
          }}>{`kontaktuj nás`}</MDXTag>{`.`}</MDXTag></MDXTag>
      <MDXTag name="h3" components={components}>{`Ako podporiť?`}</MDXTag>
      <MDXTag name="p" components={components}>{`Príspevky prijímame na:`}</MDXTag>
      <CryptoAddresses />
      <MDXTag name="p" components={components}><MDXTag name="strong" components={components} parentName="p">{`BTC`}</MDXTag>{` cez `}<MDXTag name="strong" components={components} parentName="p">{`Lightning Network`}</MDXTag>{` jednoducho pomocou služby Tippin.me:`}</MDXTag>
      <TippinWidget />
      <MDXTag name="p" components={components}>{`Za každú pomoc `}<MDXTag name="strong" components={components} parentName="p">{`úprimne ďakujeme`}</MDXTag>{`!`}</MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="img" components={components} parentName="p" props={{
          "src": "https://i.giphy.com/media/14bhmZtBNhVnIk/giphy.webp",
          "alt": "thanks"
        }}></MDXTag></MDXTag>
      <Button to="/zapoj-sa">Ďalšie možnosti ako sa zapojiť</Button>
           </MDXTag>;
  }

}
MDXContent.isMDXComponent = true;
export const _frontmatter = {};
      